import styled from '@emotion/styled';

export const Form = styled.form`
  display: flex;
  flex-flow: column nowrap;
  margin: 0;

  button {
    display: block;
    margin: 0 0 0 auto;
  }
`;

export const FormItem = styled.div`
  margin: 0 0 1rem;

  input, textarea {
    display: block;
    width: 100%;
    padding: 8px 16px;
    background: #f5f5f5;
    border: 1px solid #f5f5f5;
    outline: none;
    transition: border-color .3s;

    &:hover,
    &:focus {
      border-color: #e5e5e5;
    }

    ::placeholder {
      color: #888;
      font-family: 'Open Sans', sans-serif;
    }
  }

  textarea {
    display: block;
    min-width: 100%;
    resize: vertical;
  }
`;
