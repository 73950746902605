import React from 'react';
import { Link } from 'gatsby';
import { BreadcrumbsWrapper, List, Item } from './Breadcrumbs.styled';

const Breadcrumbs = ({ crumbs }) => (
  <BreadcrumbsWrapper className="container">
    <List>
      {crumbs.map((crumb, i) => <Item key={`breadcrumb-${i}`}><Link to={crumb.url} activeClassName="active">{crumb.title}</Link></Item>)}
    </List>
  </BreadcrumbsWrapper>
);

export default Breadcrumbs;
