import styled from '@emotion/styled';

export const NotificationContent = styled.div`
  display: block;
  max-width: 90vw;
  position: fixed;
  z-index: 1090;
  top: 20px;
  left: 50%;
  padding: .75rem 1.25rem;
  background-color: ${({ error }) => error ? "#f8d7da" : "#d4edda"};
  border: 1px solid ${({ error }) => error ? "#f5c6cb" : "#c3e6cb"};
  transform: translateX(-50%);
  font-size: .9375rem;
  color: ${({ error }) => error ? "#721c24" : "#155724"};

  @media(max-width: 767px) {
    width: 100%;
    text-align: center;
  }
`;
