import styled from '@emotion/styled';

export const StyledButton = styled.button`
  display: ${({ display }) => display ? display : 'inline-block'};
  position: relative;
  padding: ${({ variant }) => variant === 'border' ? '16px 24px' : '17px 25px'};
  background: ${({ variant }) => variant && variant === 'primary' ? '#d72226' : 'none'};
  border: ${({ variant, color }) => variant && variant === 'border' ? `1px solid ${color}` : 'none'};
  outline: none;
  font-family: 'Poppins', sans-serif;
  font-size: .875rem;
  line-height: 1.15;
  color: ${({ color }) => color ? color : 'inherit'};
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 700;
  transition: color .3s, background .3s;
  cursor: pointer;

  &:hover {
    color: ${({ variant, contrast }) => variant !== 'primary' && contrast};
    background: ${({ variant, color }) => variant === 'border' ? color : '#c21e21'};
  }
`;
