import React from 'react';
import { createPortal } from 'react-dom';
import { NotificationContent } from './Notification.styled';

const NotificationGateway = ({ children }) => {
  const target = (typeof (window) !== 'undefined') ? document.body : null;
  return (typeof (window) === 'undefined') ? null : createPortal(children, target);
};

const Notification = ({open, setOpen, error, children}) => {
  React.useEffect(() => {
    const timeout = setTimeout(() => setOpen(false), 5000);
    return () => clearTimeout(timeout);
  });

  return (
    open ? (
      <NotificationGateway>
        <NotificationContent error={error}>
          {children}
        </NotificationContent>
      </NotificationGateway>
    ) : null
  );
};

export default Notification;
