import React from 'react';
import Button from '../Button';
import Notification from '../Notification';
import { Form, FormItem } from './ContactForm.styled';

const ContactForm = () => {
  const [sendingMail, setSendingMail] = React.useState(false);
  const [notificationVisible, setNotificationVisible] = React.useState(false);
  const [notificationContent, setNotificationContent] = React.useState({
    error: false,
    message: ""
  });

  const showNotification = isError => {
    setNotificationVisible(true);
    setNotificationContent({
      error: isError,
      message: isError ?
        "Nie udało się wysłać wiadomości. Spróbój ponownie później lub skontaktuj się z nami telefonicznie." :
        "Wiadomość została wysłana. Wkrótce się z Tobą skontaktujemy."
    });
  };

  const clearForm = elements => elements.forEach(element => element.value = null);

  const sendMail = event => {
    event.preventDefault();
    const { name, mail, message } = event.target.elements;
    setSendingMail(true);
    fetch('https://admin-expozytor.codemag.pl/api/forms/submit/contactForm?token=5b84c5278d501b8131fbc8ab1ca660', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "form": {
          "Imię i Nazwisko": name.value,
          "Email": mail.value,
          "Wiadomość": message.value
        }
      })
    }).then(entry => entry.json()).then(entry => {
      showNotification(entry.error ? true : false);
      !entry.error && clearForm([name, mail, message]);
      setSendingMail(false);
    }).catch(() => {
      showNotification(true);
      setSendingMail(false);
    });
  };

  return (
    <>
      <Form onSubmit={sendMail}>
        <FormItem>
          <input type="text" name="name" placeholder="Imię i Nazwisko" required/>
        </FormItem>
        <FormItem>
          <input type="email" name="mail" placeholder="Email" required/>
        </FormItem>
        <FormItem>
          <textarea name="message" cols="30" rows="5" placeholder="Wiadomość" required/>
        </FormItem>
        <Button type="submit" variant='primary' color='white' disabled={sendingMail}>
          {sendingMail ? 'Wysyłanie...' : 'Wyślij'}
        </Button>
      </Form>
      <Notification open={notificationVisible} setOpen={setNotificationVisible} error={notificationContent.error}>
        {notificationContent.message}
      </Notification>
    </>
  );
};

export default ContactForm;
