import styled from '@emotion/styled';

export const BreadcrumbsWrapper = styled.div`
  position: relative;
  z-index: 2;
`;

export const List = styled.ul`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  margin: 0 -6px;
  padding: 20px 0 0;
  list-style: none;

  @media (min-width: 1440px) {
    padding: 30px 0 0;
  }
`;

export const Item = styled.li`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  font-size: .9375rem;

  &:not(:last-of-type){
    &:after {
      content: '/';
      color: inherit;
      opacity: .5;
    }
  }

  a {
    display: block;
    padding: 0 6px;
    color: inherit;
    opacity: .6;
    text-decoration: none;
    transition: opacity .3s;

    &.active {
      pointer-events: none;
    }

    &:hover,
    &.active {
      opacity: 1;
    }
  }
`;
