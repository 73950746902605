import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Seo from '../components/SEO';
import Layout from '../components/Layout';
import Breadcrumbs from '../components/Breadcrumbs';
import Button from '../components/Button';
import ContactForm from '../components/ContactForm';
import * as style from '../assets/style/pages/contact-page.module.scss';

const ContactPage = ({ location, data }) => {
  const crumbs = [
    { title: 'Strona główna', url: '/' },
    { title: 'Kontakt', url: '/kontakt' }
  ];
  const contactPage = () => {
    const pageData = data.contact;
    return {
      map: pageData.map.value,
      mapUrl: pageData.mapUrl.value,
      street: pageData.street.value,
      city: pageData.city.value,
      postalCode: pageData.postalCode.value,
      phone: pageData.phone.value.map(({ value }) => value),
      email: pageData.email.value,
      salesPhone: pageData.salesPhone.value,
      printingPhone: pageData.printingPhone.value
    }
  }

  return (
    <Layout>
      <Seo title="Kontakt">
        <script type="application/ld+json">
          {`{
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement":
            [
              ${crumbs.map(({title, url}, i) => `
                {
                  "@type": "ListItem",
                  "position": ${i+1},
                  "item": {
                    "@type": "WebPage",
                    "@id": "${location.origin + url}",
                    "name": "${title}"
                  }
                }
              `)}
            ]
          }`}
        </script>
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "Organization",
            "url": "https://expozytor.pl",
            "name": "Grupa EXPOZYTOR",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "${contactPage().city}, Polska",
              "postalCode": "${contactPage().postalCode}",
              "streetAddress": "${contactPage().street}"
            },
            "contactPoint" : [
              ${contactPage().phone.map((phone, i ) => `
                {
                  "@type" : "ContactPoint",
                  "telephone" : "${phone}",
                  "contactType" : "Telefon kontaktowy ${i + 1}"
                }
              `)},
              {
                "@type" : "ContactPoint",
                "telephone" : "${contactPage().salesPhone}",
                "contactType" : "Dział handlowy"
              },
              {
                "@type" : "ContactPoint",
                "telephone" : "${contactPage().printingPhone}",
                "contactType" : "Drukarnia, laser"
              }
            ],
            "email": "${contactPage().email}"
          }`}
        </script>
      </Seo>
      <Breadcrumbs crumbs={crumbs} />
      <section className={`section ${style.contact}`}>
        <div className="container">
          <div className={style.contact__map}>
            <GatsbyImage image={getImage(contactPage().map)} alt="Mapa" />
            <Button as="a" href={contactPage().mapUrl} variant='primary' color='white' target="_blank">
              Pokaż mapę
            </Button>
          </div>
          <h2 className="section__title section__title--center">
            <span>Kontakt</span>
            Skontaktuj się z nami.
          </h2>
          <div className={`row ${style.contactRow}`}>
            <div className={`col-12 col-md-6 offset-lg-1 col-lg-5 ${style.addressColumn}`}>
              <address>
                <p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24"><path d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z" /></svg>
                  <strong>Lokalizacja</strong>
                  <span>{contactPage().street}, {contactPage().postalCode} {contactPage().city}</span>
                </p>
                <p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24"><path d="M20 22.621l-3.521-6.795c-.008.004-1.974.97-2.064 1.011-2.24 1.086-6.799-7.82-4.609-8.994l2.083-1.026-3.493-6.817-2.106 1.039c-7.202 3.755 4.233 25.982 11.6 22.615.121-.055 2.102-1.029 2.11-1.033z" /></svg>
                  <strong>Telefon</strong>
                  {contactPage().phone.map((phone, i) => (
                    <span key={`phone-${i}`}><a href={`tel:${phone}`}>{phone}</a>{i < contactPage().phone.length - 1 && ', '}</span>
                  ))}
                </p>
                <p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24"><path d="M12 12.713l-11.985-9.713h23.971l-11.986 9.713zm-5.425-1.822l-6.575-5.329v12.501l6.575-7.172zm10.85 0l6.575 7.172v-12.501l-6.575 5.329zm-1.557 1.261l-3.868 3.135-3.868-3.135-8.11 8.848h23.956l-8.11-8.848z" /></svg>
                  <strong>Email</strong>
                  <a href={`mailto:${contactPage().email}`}>{contactPage().email}</a>
                </p>
              </address>
            </div>
            <div className={`col-12 col-md-6 col-lg-5 ${style.formColumn}`}>
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query {
    contact: cockpitContactPage {
      map {
        value {
          childImageSharp {
            gatsbyImageData(
              width: 1110,
              quality: 100,
              placeholder: BLURRED,
              formats: WEBP
            )
          }
        }
      }
      mapUrl {
        value
      }
      street {
        value
      }
      city {
        value
      }
      postalCode {
        value
      }
      phone {
        value {
          value
        }
      }
      email {
        value
      }
      salesPhone {
        value
      }
      printingPhone {
        value
      }
    }
  }
`;

export default ContactPage;
